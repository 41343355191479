import React from "react";

const Technologies = () => {
    const techItems = [
        {
            title: "AI/ML",
            description: "Harness the power of Artificial Intelligence and Machine Learning for innovative solutions.",
            img: "https://media.istockphoto.com/id/1452604857/photo/businessman-touching-the-brain-working-of-artificial-intelligence-automation-predictive.jpg?s=612x612&w=0&k=20&c=GkAOxzduJbUKpS2-LX_l6jSKtyhdKlnPMo2ito4xpR4=", // Replace with an AI/ML-themed image or logo
        },
        {
            title: "Java",
            description: "Versatile and robust programming language widely used for enterprise applications.",
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIyYYAhEoXWOcyx0sjHiaK2TrmUNxxMJdE6g&s", // Replace with a Java logo
        },
        {
            title: "Python",
            description: "A versatile and beginner-friendly programming language for web, data science, and AI.",
            img: "https://images.ctfassets.net/23aumh6u8s0i/6uBzrqHNLlSAoER6HtgDN0/accd8f871b1de37f472b94da4346afa2/python-hero", // Replace with a Python logo
        },
        {
            title: ".NET",
            description: "Microsoft's framework for building scalable and secure applications.",
            img: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg", // Replace with a .NET logo
        },        
        {
            title: "MERN Stack",
            description: "A powerful full-stack solution using MongoDB, Express.js, React, and Node.js.",
            img: "https://wallpapercave.com/wp/wp8725091.jpg",
        },
        {
            title: "PHP",
            description: "Server-side scripting language for web development.",
            img: "https://c4.wallpaperflare.com/wallpaper/221/920/32/code-wallpaper-preview.jpg", // Replace with a PHP logo
        },
        {
            title: "WordPress",
            description: "Content management system for building websites.",
            img: "https://img.freepik.com/premium-photo/wordpress-social-media-simple-minimal-modern-corporate-gradient-3d-illustration-background_476123-2250.jpg?semt=ais_hybrid", // Replace with a WordPress logo
        },
        {
            title: "Laravel",
            description: "A robust PHP framework for modern web applications.",
            img: "https://thumbs.dreamstime.com/b/laravel-web-framework-logo-logo-laravel-web-framework-samsung-tablet-laravel-free-open-source-php-web-framework-118523659.jpg", // Replace with a Laravel logo
        },
        {
            title: "Next.js",
            description: "React framework for building server-rendered web applications.",
            img: "https://codingmart.com/wp-content/uploads/2020/12/kh3ntlg6dnemjeaz531z.jpg", // Replace with a Next.js logo
        },
        {
            title: "JavaScript",
            description: "The backbone of modern web development.",
            img: "https://c4.wallpaperflare.com/wallpaper/966/672/905/javascript-minimalism-wallpaper-preview.jpg", // Replace with a JavaScript logo
        },
        {
            title: "React Native",
            description: "A library for building user interfaces.",
            img: "https://c4.wallpaperflare.com/wallpaper/294/834/442/reactjs-facebook-javascript-minimalism-wallpaper-preview.jpg", // Replace with a React logo
        },
        {
            title: "Node.js",
            description: "JavaScript runtime for server-side development.",
            img: "https://c4.wallpaperflare.com/wallpaper/619/468/16/node-js-javascript-wallpaper-preview.jpg", // Replace with a Node.js logo
        },
    ];

    return (
        <div id="tech" className="p-8 bg-gradient-to-r from-blue-50 via-blue-100 to-white">
            {/* Heading Section */}
            <div className="text-center mb-10">
                <h1 className="text-4xl font-extrabold text-gray-800">
                    Our Expertise in Cutting-Edge Technologies
                </h1>
                <p className="text-gray-600 mt-4 text-lg">
                    We provide services using the most popular technologies to build innovative solutions for web and mobile.
                </p>
            </div>

            {/* Technologies Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {techItems.map((tech, index) => (
                    <div
                        key={index}
                        className="flex items-center space-x-6 bg-white shadow-lg p-6 rounded-lg border border-gray-300 hover:shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out"
                    >
                        <img
                            src={tech.img}
                            alt={tech.title}
                            className="w-16 h-16 rounded-full border-2 border-gray-300 shadow-sm"
                        />
                        <div>
                            <h3 className="text-xl font-semibold text-gray-800">{tech.title}</h3>
                            <p className="text-gray-500 text-sm mt-2">{tech.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Technologies;
