import React, { useEffect, useState } from "react";

const HelpCenterPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const helpTopics = [
        {
            question: "I am unable to contact support, what should I do?",
            answer:
                "If you are unable to contact support via the usual methods (email, live chat), ensure you are using the correct contact details on the 'Contact Us' page. If the issue persists, try using our social media channels or submitting a support ticket directly from the app.",
        },
        {
            question: "How can I report a bug or technical issue with the software?",
            answer:
                "To report a bug or technical issue, navigate to the 'Support' section in the app and select 'Report an Issue.' Include as much detail as possible, including screenshots or error logs. Our support team will respond within 24-48 hours.",
        },
        {
            question: "I have a billing issue, how do I resolve it?",
            answer:
                "For billing-related issues, please visit the 'Billing' section in the app. You can update your payment methods, view invoices, and resolve any payment issues. If the problem persists, contact our billing support team via email or phone.",
        },
        {
            question: "How do I track my project’s progress or status?",
            answer:
                "To track the progress of your software development project, please check your dashboard where you will find project milestones, task completion rates, and communication updates. You can also contact your project manager for a more detailed progress report.",
        },
        {
            question: "Can I request a project update or feature addition?",
            answer:
                "Yes, if you would like an update on your project or wish to request additional features, simply log in to the client portal and submit a request under 'Project Updates.' Our team will review your request and provide feedback within 48 hours.",
        },
        {
            question: "How can I communicate with the development team?",
            answer:
                "Communication with your development team is available through the app’s messaging system. You can also schedule video or voice calls via the app for live discussions. For urgent matters, please use our direct contact options in the 'Support' section.",
        },
        {
            question: "What happens if my software development project is delayed?",
            answer:
                "If there are delays in your project, we will notify you promptly via email or through the app’s notification system. You will receive a revised timeline and an explanation for the delay. We prioritize transparency and strive to keep you informed at every stage of the development process.",
        },
        {
            question: "How do I provide feedback or rate the service?",
            answer:
                "To provide feedback or rate our services, go to the 'Feedback' section in the app. Here, you can submit your thoughts on the development process, team communication, and overall satisfaction. We value your input and strive to improve with every project.",
        },
    ];

    const filteredHelpTopics = helpTopics.filter((topic) =>
        topic.question.toLowerCase().includes(searchQuery)
    );

    return (
        <div className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
                {/* Hero Section */}
                <section className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-blue-600 mb-4">Help Center</h1>
                    <p className="text-lg text-gray-700">
                        Find solutions to common issues related to our software development services, or contact us for further assistance.
                    </p>
                </section>

                {/* Search Bar */}
                <section className="mb-12">
                    <input
                        type="text"
                        placeholder="Search for help..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full md:w-1/2 mx-auto p-4 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </section>

                {/* Help Topics Accordion Section */}
                <section className="space-y-8">
                    {filteredHelpTopics.length > 0 ? (
                        filteredHelpTopics.map((topic, index) => (
                            <div
                                key={index}
                                className="bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105"
                            >
                                <div
                                    className="flex justify-between items-center cursor-pointer"
                                    onClick={() => handleToggle(index)}
                                >
                                    <h3 className="text-xl font-semibold text-gray-800">{topic.question}</h3>
                                    <span
                                        className={`transform transition duration-200 ${activeIndex === index ? "rotate-180" : ""
                                            }`}
                                    >
                                        <i className="fas fa-chevron-down"></i>
                                    </span>
                                </div>
                                {activeIndex === index && (
                                    <p className="mt-4 text-gray-600">{topic.answer}</p>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="bg-white p-6 shadow-lg rounded-lg">
                            <p className="text-gray-600">No results found. Please try a different search term.</p>
                        </div>
                    )}
                </section>

                {/* Contact Us Section */}
                <section className="mt-12 text-center">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4">Need Further Assistance?</h2>
                    <p className="text-lg text-gray-600 mb-6">
                        If you encounter any issues related to contacting us, project updates, or any other concerns, we're here to help. Reach out to our support team for more personalized assistance.
                    </p>
                    <a
                        href="#contact"
                        className="bg-blue-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-500"
                    >
                        Contact Support
                    </a>
                </section>
            </div>
        </div>
    );
};

export default HelpCenterPage;
