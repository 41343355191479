import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";
import WebDesignPage from "./pages/WebDesignPage";
import MobileAppsPage from "./pages/MobileAppsPage";
import SoftwareDevelopmentPage from "./pages/SoftwareDevelopmentPage";
import SEOPage from "./pages/SEOPage";
import UIUXDesignPage from "./pages/UIUXDesignPage";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";
import FAQPage from "./pages/FAQPage";
import HelpCenterPage from "./pages/HelpCenterPage";
import ContactSupportPage from "./pages/ContactSupportPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="web-design" element={<WebDesignPage />} />
          <Route path="mobile-app" element={<MobileAppsPage />} />
          <Route path="software-development" element={<SoftwareDevelopmentPage />} />
          <Route path="seo" element={<SEOPage />} />
          <Route path="ui-ux" element={<UIUXDesignPage />} />
          <Route path="digital-marketing" element={<DigitalMarketingPage />} />
          <Route path="faq" element={<FAQPage />} />
          <Route path="help-center" element={<HelpCenterPage />} />
          <Route path="contact-support" element={<ContactSupportPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
