import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import emailjs from '@emailjs/browser';

const MobileAppsPage = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission status

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Simple form validation
    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.email) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
        if (!formData.message) errors.message = "Message is required";
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission with EmailJS
    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Form is valid, send to EmailJS
            emailjs
                .sendForm(
                    'YOUR_SERVICE_ID',      // Replace with your EmailJS service ID
                    'YOUR_TEMPLATE_ID',      // Replace with your EmailJS template ID
                    e.target,                // Target is the form itself
                    'YOUR_USER_ID'           // Replace with your EmailJS user ID
                )
                .then(
                    (result) => {
                        console.log('Success:', result.text);
                        setFormSubmitted(true); // Form successfully submitted
                        setFormData({ name: "", email: "", message: "" }); // Clear form after submission
                    },
                    (error) => {
                        console.log('Error:', error.text);
                    }
                );
        }
    };

    return (
        <div className="bg-gray-50">
            {/* Hero Section */}
            <section className="bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white text-center py-28">
                <div className="container mx-auto px-4">
                    <h1 className="text-5xl font-bold mb-4 transform transition-all duration-500 hover:scale-110">
                        Mobile Apps Development
                    </h1>
                    <p className="text-lg mb-6 animate__animated animate__fadeInUp">
                        Create powerful, scalable, and user-friendly mobile applications for iOS and Android.
                    </p>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        className="bg-white text-green-600 px-8 py-4 rounded-md font-semibold text-lg hover:bg-gray-100 transition ease-in-out duration-300"
                    >
                        Get Started
                    </Link>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-10">Why Choose Us?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Feature 1 */}
                        <div className="p-8 bg-white shadow-lg rounded-lg text-center transform transition-all hover:scale-105 hover:shadow-2xl hover:bg-green-50">
                            <h3 className="text-xl font-semibold mb-4">Cross-Platform Apps</h3>
                            <p className="text-gray-600">
                                Build apps that work seamlessly on both iOS and Android.
                            </p>
                        </div>
                        {/* Feature 2 */}
                        <div className="p-8 bg-white shadow-lg rounded-lg text-center transform transition-all hover:scale-105 hover:shadow-2xl hover:bg-green-50">
                            <h3 className="text-xl font-semibold mb-4">Custom UI/UX Design</h3>
                            <p className="text-gray-600">
                                Create visually appealing and user-friendly designs.
                            </p>
                        </div>
                        {/* Feature 3 */}
                        <div className="p-8 bg-white shadow-lg rounded-lg text-center transform transition-all hover:scale-105 hover:shadow-2xl hover:bg-green-50">
                            <h3 className="text-xl font-semibold mb-4">High Performance</h3>
                            <p className="text-gray-600">
                                Develop apps optimized for speed and reliability.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="bg-green-600 text-white text-center py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-6">Let’s Build Your App Today!</h2>
                    <p className="text-lg mb-6">
                        From concept to deployment, we bring your app ideas to life.
                    </p>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        className="bg-white text-green-600 px-8 py-4 rounded-md font-semibold text-lg hover:bg-gray-100 transition ease-in-out duration-300"
                    >
                        Contact Us
                    </Link>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>
                    {formSubmitted ? (
                        <div className="bg-green-100 text-green-700 p-6 rounded-lg shadow-lg text-center">
                            <h3 className="text-2xl font-semibold mb-4">Success!</h3>
                            <p>Your message has been successfully submitted. We'll get back to you soon.</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
                            {/* Name */}
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.name && <span className="text-red-600 text-sm">{formErrors.name}</span>}
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.email && <span className="text-red-600 text-sm">{formErrors.email}</span>}
                            </div>

                            {/* Message */}
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                ></textarea>
                                {formErrors.message && <span className="text-red-600 text-sm">{formErrors.message}</span>}
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-green-600 text-white py-3 rounded-md font-semibold hover:bg-green-700"
                            >
                                Submit
                            </button>
                        </form>
                    )}
                </div>
            </section>
        </div>
    );
};

export default MobileAppsPage;
