import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import WhatsAppButton from "../WhatsAppButton";

const Layout = () => {
    return (
        <div className="layout-container">
            <Navbar />
            <main className="main-content">
                <Outlet />
            </main>
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default Layout;
