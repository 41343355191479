import React, { useState } from "react";

export const WhatsAppButton = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    // Handle WhatsApp popup toggle
    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    // WhatsApp link (use your phone number here)
    const whatsAppLink = "https://wa.me/7978763604";

    return (
        <div>
            {/* WhatsApp Icon in Bottom Right */}
            <div
                onClick={togglePopup}
                className="fixed bottom-16 right-8 p-3 bg-green-500 rounded-full shadow-lg cursor-pointer z-50"
            >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp logo
                    alt="WhatsApp"
                    className="w-12 h-12"
                />
            </div>

            {/* WhatsApp Popup (Only visible when toggled) */}
            {isPopupVisible && (
                <div className="fixed bottom-24 right-8 w-80 p-4 bg-white rounded-lg shadow-lg z-50">
                    <h3 className="text-xl font-semibold">Contact us on WhatsApp!</h3>
                    <p className="text-gray-600 mb-4">Click below to start chatting:</p>
                    <a
                        href={whatsAppLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-green-500 text-white py-2 px-4 rounded-lg block text-center"
                    >
                        Start Chat
                    </a>
                    <button
                        onClick={togglePopup}
                        className="text-gray-500 mt-2 hover:text-gray-700"
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default WhatsAppButton;
