import React, { useEffect } from "react";

const PrivacyPolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
                {/* Hero Section */}
                <section className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-blue-600 mb-4">Privacy Policy</h1>
                    <p className="text-lg text-gray-700">
                        Your privacy is important to us. Please read our privacy policy to understand how we collect, use, and protect your personal information.
                    </p>
                </section>

                {/* Content Section */}
                <section className="bg-white p-8 shadow-lg rounded-lg">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-6">Introduction</h2>
                    <p className="text-gray-600 mb-4">
                        This Privacy Policy outlines how we collect, use, and protect the information you provide to us. By using our services, you consent to the practices described in this policy.
                    </p>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Information We Collect</h3>
                    <p className="text-gray-600 mb-4">
                        We collect information that you provide to us directly when you use our services, including but not limited to:
                    </p>
                    <ul className="list-disc pl-8 mb-6">
                        <li>Personal information such as your name, email, and contact details.</li>
                        <li>Usage data, including information about your interactions with our website or app.</li>
                        <li>Device and connection information, such as browser type, operating system, and IP address.</li>
                    </ul>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">How We Use Your Information</h3>
                    <p className="text-gray-600 mb-4">
                        We use the information we collect to:
                    </p>
                    <ul className="list-disc pl-8 mb-6">
                        <li>Provide and improve our services to you.</li>
                        <li>Respond to your inquiries or requests.</li>
                        <li>Send you updates, promotional materials, and other relevant information (if you opt-in).</li>
                        <li>Ensure the security and integrity of our platform.</li>
                    </ul>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Data Protection</h3>
                    <p className="text-gray-600 mb-4">
                        We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, or destruction. However, please be aware that no method of data transmission over the internet is 100% secure.
                    </p>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Sharing Your Information</h3>
                    <p className="text-gray-600 mb-4">
                        We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following circumstances:
                    </p>
                    <ul className="list-disc pl-8 mb-6">
                        <li>With trusted third-party service providers who assist us in providing services.</li>
                        <li>If required by law or in response to legal processes, such as a court order.</li>
                        <li>In the event of a merger, acquisition, or sale of our assets.</li>
                    </ul>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Your Rights and Choices</h3>
                    <p className="text-gray-600 mb-4">
                        You have the right to:
                    </p>
                    <ul className="list-disc pl-8 mb-6">
                        <li>Access, correct, or delete your personal information.</li>
                        <li>Opt-out of receiving promotional communications from us.</li>
                        <li>Request a copy of the information we have about you.</li>
                    </ul>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Cookies and Tracking Technologies</h3>
                    <p className="text-gray-600 mb-4">
                        We use cookies and similar tracking technologies to enhance your experience on our website. These tools help us remember your preferences, analyze website traffic, and improve our services.
                    </p>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Children's Privacy</h3>
                    <p className="text-gray-600 mb-4">
                        Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If we learn that we have collected personal information from a child under 13, we will take steps to delete that information.
                    </p>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Changes to This Privacy Policy</h3>
                    <p className="text-gray-600 mb-4">
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                    </p>

                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h3>
                    <p className="text-gray-600 mb-4">
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:support@example.com" className="text-blue-600">support@example.com</a>.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
