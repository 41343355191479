import React, { useEffect, useState } from "react";

const SoftwareDevelopmentPage = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission status

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Simple form validation
    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.email) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
        if (!formData.message) errors.message = "Message is required";
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Simulate form submission (e.g., sending to EmailJS or a server)
            setFormSubmitted(true);
            setFormData({ name: "", email: "", message: "" }); // Clear form fields after submission
        }
    };

    return (
        <div className="bg-gray-50">
            {/* Hero Section */}
            <section className="bg-indigo-600 text-white text-center py-20">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl font-bold mb-4">Software Development</h1>
                    <p className="text-lg">
                        Empowering businesses with scalable, efficient, and custom software solutions.
                    </p>
                </div>
            </section>

            {/* Features/Services Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-10">Our Expertise</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Feature 1 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transform transition duration-300 hover:scale-105">
                            <h3 className="text-xl font-semibold mb-4">Custom Software</h3>
                            <p className="text-gray-600">
                                Tailored solutions designed to meet your unique business needs.
                            </p>
                        </div>
                        {/* Feature 2 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transform transition duration-300 hover:scale-105">
                            <h3 className="text-xl font-semibold mb-4">Enterprise Applications</h3>
                            <p className="text-gray-600">
                                Robust applications to streamline and scale your operations.
                            </p>
                        </div>
                        {/* Feature 3 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transform transition duration-300 hover:scale-105">
                            <h3 className="text-xl font-semibold mb-4">Cloud Solutions</h3>
                            <p className="text-gray-600">
                                Modern cloud-based software for flexibility and scalability.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="bg-indigo-600 text-white text-center py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-6">Let’s Build Something Amazing</h2>
                    <p className="text-lg mb-6">
                        From initial concept to final deployment, we bring your software vision to life.
                    </p>
                    <a
                        href="#contact"
                        className="bg-white text-indigo-600 px-6 py-3 rounded-md font-semibold hover:bg-gray-100 transition ease-in-out duration-300"
                    >
                        Contact Us
                    </a>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>
                    {formSubmitted ? (
                        <div className="bg-green-100 text-green-700 p-6 rounded-lg shadow-lg text-center">
                            <h3 className="text-2xl font-semibold mb-4">Success!</h3>
                            <p>Your message has been successfully submitted. We'll get back to you soon.</p>
                        </div>
                    ) : (
                        <form
                            onSubmit={handleSubmit}
                            className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg"
                        >
                            {/* Name */}
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.name && <span className="text-red-600 text-sm">{formErrors.name}</span>}
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.email && <span className="text-red-600 text-sm">{formErrors.email}</span>}
                            </div>

                            {/* Message */}
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                ></textarea>
                                {formErrors.message && <span className="text-red-600 text-sm">{formErrors.message}</span>}
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-indigo-600 text-white py-3 rounded-md font-semibold hover:bg-indigo-700"
                            >
                                Submit
                            </button>
                        </form>
                    )}
                </div>
            </section>
        </div>
    );
};

export default SoftwareDevelopmentPage;
