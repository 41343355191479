import React from 'react';
import Services from './Services';

const ServiceSection = () => {
    return (
        <div id="services" className="bg-gradient-to-r from-blue-100 to-blue-200 py-16 px-6">
            {/* Title Section */}
            <div className="text-center mb-12">
                <h2 className="text-4xl font-extrabold text-gray-800 mb-4">
                    Software Development and Design
                </h2>
                <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                    We understand every tech journey is different. Whether you're a startup gearing up for investment, a company seeking technological solutions, or a multinational searching for new answers to old problems — we envision and create the right solutions for every software development need.
                </p>
            </div>

            {/* Service Boxes */}
            <div className="flex flex-col md:flex-row gap-8 w-full justify-center">
                <Services />
            </div>
        </div>
    );
};

export default ServiceSection;
