import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet"; // Ensure you have this library installed
import BannerCarousel from "../components/BannerCarousel";
import ServiceSection from "../components/ServiceSection";
import Technologies from "../components/Technologies";
import FAQ from "../components/Faq";
import ContactUs from "../components/ContactUs";
import AboutUs from "../components/AboutUs";
import LetsTalk from "../components/LetsTalk";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Page Metadata */}
      <Helmet>
        <title>Innovative Dev - Empowering Digital Solutions</title>
        <meta
          name="description"
          content="Innovative Dev offers cutting-edge software development, digital marketing, and AI-powered solutions to empower businesses in the digital age."
        />
        <meta
          name="keywords"
          content="Innovative Dev, software development, digital marketing, AI solutions, web development, mobile apps"
        />
        <meta name="author" content="Innovative Dev" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />

        {/* Open Graph Metadata */}
        <meta
          property="og:title"
          content="Innovative Dev - Empowering Digital Solutions"
        />
        <meta
          property="og:description"
          content="Innovative Dev offers cutting-edge software development, digital marketing, and AI-powered solutions to empower businesses in the digital age."
        />
        <meta property="og:url" content="https://www.innovativedev.in/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/de4wmkcvu/image/upload/v1732549097/InnovativeDev_1_zhyvzd.jpg"
        />
      </Helmet>

      {/* Page Content */}
      <Fragment>
        <BannerCarousel />
        <ServiceSection />
        <Technologies />
        {/* <ClientsSection /> */}
        {/* <ExploreProjects /> */}
        {/* <GetInTouchBanner /> */}
        {/* <ClientsCarousel /> */}
        {/* <CTASection /> */}
        {/* <CaseStudy /> */}
        {/* <GetInTouchBanner /> */}
        {/* <EmpoweringSuccess /> */}
        <FAQ />
        <ContactUs />
        <AboutUs />
        <LetsTalk />
        {/* <ContactButton /> */}
      </Fragment>
    </>
  );
};

export default HomePage;
