import React, { useEffect, useState } from "react";

const ContactSupportPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // Simulate form submission
            setTimeout(() => {
                setIsSubmitting(false);
                setSuccessMessage("Your message has been sent successfully! We'll get back to you shortly.");
                setErrorMessage("");
                setFormData({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                });
            }, 2000);
        } catch (error) {
            setIsSubmitting(false);
            setErrorMessage("Something went wrong. Please try again later.");
            setSuccessMessage("");
        }
    };

    return (
        <div className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
                {/* Hero Section */}
                <section className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-blue-600 mb-4">Contact Support</h1>
                    <p className="text-lg text-gray-700">
                        We're here to assist you with any issues or queries related to our software development services.
                    </p>
                </section>

                {/* Contact Form Section */}
                <section className="bg-white p-8 shadow-lg rounded-lg">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Submit a Support Request</h2>
                    {successMessage && (
                        <div className="bg-green-100 text-green-700 p-4 mb-6 rounded-md">
                            <p>{successMessage}</p>
                        </div>
                    )}
                    {errorMessage && (
                        <div className="bg-red-100 text-red-700 p-4 mb-6 rounded-md">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-sm font-semibold text-gray-600 mb-2">
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-semibold text-gray-600 mb-2">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="subject" className="block text-sm font-semibold text-gray-600 mb-2">
                                Subject
                            </label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>

                        <div className="mb-6">
                            <label htmlFor="message" className="block text-sm font-semibold text-gray-600 mb-2">
                                Your Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="4"
                                required
                            ></textarea>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className={`bg-blue-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-500 focus:outline-none ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Sending..." : "Submit Request"}
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    );
};

export default ContactSupportPage;
