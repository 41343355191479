import React, { useState } from "react";

const LetsTalk = () => {
  const [showModal, setShowModal] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setShowModal(false); // Close the form modal
    setShowGreeting(true); // Show the greeting modal
  };

  return (
    <>
      {/* Let's Talk Section */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-500 py-16 text-white">
        <div className="text-center max-w-3xl mx-auto px-6">
          <h2 className="text-3xl font-bold">Let's Talk</h2>
          <p className="mt-4 text-lg leading-relaxed">
          We’re passionate about all things tech and would love to hear from you. Reach out to us to discuss anything on your mind, whether it’s brainstorming ideas, refining your concept, evaluating your existing code, planning your project’s roadmap, or exploring feasibility. Our free consultation is designed to guide you in the right direction and help bring your vision to life.
          </p>
          <button
            onClick={() => setShowModal(true)}
            className="mt-6 bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300"
          >
            Schedule a Free Consultation
          </button>
        </div>
      </section>

      {/* Modal Form */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg w-full max-w-lg p-6 relative">
            {/* Close Button */}
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-xl"
            >
              &times;
            </button>
            {/* Form Heading */}
            <h3 className="text-xl font-semibold text-gray-800 text-center">
              Schedule Your Free Consultation
            </h3>
            <p className="text-gray-600 text-center mt-2">
              Fill out the form below, and we’ll get in touch with you shortly!
            </p>
            {/* Form */}
            <form className="mt-6 space-y-4" onSubmit={handleFormSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-gray-600 font-medium mb-2"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-600 font-medium mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-gray-600 font-medium mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  placeholder="Share your thoughts or questions"
                  className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-blue-700 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Greeting Modal */}
      {showGreeting && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg w-full max-w-md p-6 text-center relative">
            {/* Close Button */}
            <button
              onClick={() => setShowGreeting(false)}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-xl"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold text-gray-800">
              Thank You for Reaching Out!
            </h3>
            <p className="mt-4 text-gray-600">
              We appreciate you taking the time to contact us. One of our team
              members will get back to you shortly!
            </p>
            <button
              onClick={() => setShowGreeting(false)}
              className="mt-6 bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LetsTalk;
