import React, { useEffect, useState } from "react";
import { FaSearch, FaDollarSign, FaShareAlt } from "react-icons/fa"; // Icons for services
import { Link } from "react-scroll"; // For smooth scroll functionality

const DigitalMarketingPage = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Simple form validation
    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.email) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
        if (!formData.message) errors.message = "Message is required";
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Form is valid, handle form submission (e.g., send to server)
            setFormSubmitted(true); // Set formSubmitted to true when the form is successfully submitted
            setFormData({ name: "", email: "", message: "" }); // Clear form after submission
        }
    };

    return (
        <div className="bg-gray-50">
            {/* Hero Section */}
            <section className="bg-teal-600 text-white text-center py-40 bg-cover bg-center" style={{ backgroundImage: "url('https://source.unsplash.com/1600x900/?marketing')" }}>
                <div className="container mx-auto px-4 relative">
                    <h1 className="text-5xl font-bold mb-4 animate__animated animate__fadeInUp">Digital Marketing</h1>
                    <p className="text-xl mb-6 animate__animated animate__fadeInUp animate__delay-1s">
                        Drive growth and visibility for your business through proven digital marketing strategies.
                    </p>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        className="bg-white text-teal-600 px-8 py-4 rounded-md font-semibold text-lg hover:bg-teal-700 hover:text-white transition ease-in-out duration-300 animate__animated animate__fadeInUp animate__delay-2s"
                    >
                        Get Started
                    </Link>
                </div>
            </section>

            {/* Services Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-10">Our Digital Marketing Services</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        {/* Service 1 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transition transform hover:scale-105 hover:shadow-2xl hover:bg-teal-50">
                            <FaSearch className="text-4xl text-teal-600 mb-4 mx-auto" />
                            <h3 className="text-xl font-semibold mb-4">Search Engine Optimization (SEO)</h3>
                            <p className="text-gray-600">
                                Improve your website’s ranking on search engines and drive organic traffic.
                            </p>
                        </div>
                        {/* Service 2 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transition transform hover:scale-105 hover:shadow-2xl hover:bg-teal-50">
                            <FaDollarSign className="text-4xl text-teal-600 mb-4 mx-auto" />
                            <h3 className="text-xl font-semibold mb-4">Pay-Per-Click (PPC)</h3>
                            <p className="text-gray-600">
                                Increase targeted traffic quickly with effective paid search campaigns.
                            </p>
                        </div>
                        {/* Service 3 */}
                        <div className="p-6 bg-white shadow-lg rounded-lg text-center transition transform hover:scale-105 hover:shadow-2xl hover:bg-teal-50">
                            <FaShareAlt className="text-4xl text-teal-600 mb-4 mx-auto" />
                            <h3 className="text-xl font-semibold mb-4">Social Media Marketing</h3>
                            <p className="text-gray-600">
                                Build brand awareness and engagement on platforms like Facebook, Instagram, and Twitter.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="bg-teal-600 text-white text-center py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-6">Let’s Grow Your Business</h2>
                    <p className="text-lg mb-6">
                        Ready to take your digital presence to the next level? Let's work together to achieve your marketing goals.
                    </p>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        className="bg-white text-teal-600 px-8 py-4 rounded-md font-semibold text-lg hover:bg-teal-700 hover:text-white transition ease-in-out duration-300"
                    >
                        Contact Us
                    </Link>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>
                    {formSubmitted ? (
                        <div className="bg-green-100 text-green-700 p-6 rounded-lg shadow-lg text-center">
                            <h3 className="text-2xl font-semibold mb-4">Success!</h3>
                            <p>Your message has been successfully submitted. We'll get back to you soon.</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
                            {/* Name */}
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.name && <span className="text-red-600 text-sm">{formErrors.name}</span>}
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                {formErrors.email && <span className="text-red-600 text-sm">{formErrors.email}</span>}
                            </div>

                            {/* Message */}
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                ></textarea>
                                {formErrors.message && <span className="text-red-600 text-sm">{formErrors.message}</span>}
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-teal-600 text-white py-3 rounded-md font-semibold hover:bg-teal-700"
                            >
                                Submit
                            </button>
                        </form>
                    )}
                </div>
            </section>
        </div>
    );
};

export default DigitalMarketingPage;
