import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // Track scroll position to change navbar style
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Scroll to section function using scrollIntoView with offset for fixed navbar
    const scrollToSection = (id, speed) => {
        const section = document.getElementById(id);
        if (section) {
            const offset = 80; // Adjust this value to your navbar height
            const targetPosition = section.offsetTop - offset; // Subtract navbar height for proper scroll
            const behavior = "smooth"; // Smooth scroll behavior

            // Use scrollIntoView to scroll to the target position
            section.scrollIntoView({ behavior, block: "start" });

            // Close mobile menu after clicking a menu item
            setIsMobileMenuOpen(false);
        }
    };

    const navItems = [
        { name: "Services", id: "services", speed: "normal" },
        { name: "Faq", id: "faq", speed: "slow" },
        { name: "Tech", id: "tech", speed: "fast" },
        { name: "About Us", id: "about-us", speed: "normal" },
        { name: "Contact", id: "contact", speed: "normal" }
    ];

    return (
        <div>
            {/* Navbar */}
            <header
                className={`fixed top-0 w-full py-4 z-50 transition-all duration-300 ${isScrolled
                    ? "bg-white shadow-md text-gray-800"
                    : "bg-transparent text-white"
                    }`}
            >
                <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                    {/* Logo and Brand */}
                    <div className="flex items-center space-x-3">
                        <img
                            src="https://res.cloudinary.com/de4wmkcvu/image/upload/v1732549097/InnovativeDev_1_zhyvzd.jpg"
                            alt="InnovativeDev Logo"
                            className="w-10 h-10 rounded-full border border-current"
                        />
                        <Link to={"/"} >
                        
                        <span className="text-2xl font-bold tracking-wide">InnovativeDev</span>
                        </Link>
                    </div>

                    {/* Desktop Navigation Links */}
                    <div className="hidden md:flex space-x-8">
                        {navItems.map((item) => (
                            <button
                                key={item.name}
                                onClick={() => scrollToSection(item.id, item.speed)}
                                className="text-lg hover:underline hover:text-blue-500 transition duration-300"
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={toggleMobileMenu}
                            className="focus:outline-none"
                            aria-label="Toggle Mobile Menu"
                        >
                            {isMobileMenuOpen ? (
                                <svg
                                    className="w-7 h-7"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="w-7 h-7"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16m-7 6h7"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </nav>

                {/* Mobile Menu */}
                {isMobileMenuOpen && (
                    <div className="md:hidden bg-white text-gray-700 shadow-lg">
                        <div className="flex flex-col px-4 py-4 space-y-3">
                            {navItems.map((item) => (
                                <button
                                    key={item.name}
                                    onClick={() => scrollToSection(item.id, item.speed)}
                                    className="text-lg hover:text-blue-500 transition duration-300"
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </header>

            {/* Sections - These are handled in the separate HomeScreen component */}
        </div>
    );
};

export default Navbar;
