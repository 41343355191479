import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
    const services = [
        {
            title: "Web design & development",
            description: "Elevating Your Online Presence!",
            button: "Read More",
            url: "web-design",
            img: "https://res.cloudinary.com/sitsindia-com/image/upload/v1686138609/SITSindia23/website-design-development-company-bhubaneswar_zozlyb_rj1rax.webp",
        },
        {
            title: "Mobile Apps Development",
            description: "Building Tomorrow's Apps Today!",
            button: "Read More",
            url: "mobile-app",
            img: "https://res.cloudinary.com/sitsindia-com/image/upload/v1686138610/SITSindia23/mobile-app-development-company-bhubaneswar_p5erip_j3hp7s.webp",
        },
        {
            title: "Software Development",
            description: "Unleash the Power of Software Magic!",
            button: "Read More",
            url: "software-development",
            img: "https://res.cloudinary.com/sitsindia-com/image/upload/v1686138609/SITSindia23/software-development-company-bhubaneswar_wifuet_mwf6bq.webp",
        },
        {
            title: "Search Engine Optimization",
            description: "Elevate Your Online Visibility!",
            button: "Read More",
            url: "seo",
            img: "https://res.cloudinary.com/sitsindia-com/image/upload/v1686138609/SITSindia23/search-engine-optimization-bhubaneswar_p5xrur_xgyn61.webp",
        },
    ];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 p-6">
            {services.map((service, index) => (
                <Link to={service.url}>
                    <div
                        key={index}
                        className="bg-white shadow-xl rounded-lg p-6 flex flex-col items-center text-center border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300 ease-in-out"
                    >
                        <img
                            src={service.img}
                            alt={service.title}
                            className="w-24 h-24 mb-4 rounded-full object-cover shadow-lg"
                        />
                        <h3 className="text-2xl font-semibold mb-2 text-gray-800">
                            {service.title}
                        </h3>
                        <p className="text-gray-600 mb-4">{service.description}</p>
                        <button className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition duration-300 ease-in-out">
                            {service.button}
                        </button>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Services;
