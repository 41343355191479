import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white">
      {/* Top Section */}
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 py-12 border-b border-blue-400">
          {/* About Us */}
          <div>
            <h5 className="text-lg font-bold mb-4">About Us</h5>
            <p className="text-sm">
              We deliver the best solutions to simplify your business processes.
              Committed to innovation and excellence.
            </p>
          </div>

          {/* Services */}
          <div>
            <h5 className="text-lg font-bold mb-4">Services</h5>
            <ul>
              <li className="mb-2">
                <Link to={"web-design"} className="hover:underline">
                  Web Development
                </Link>
              </li>
              <li className="mb-2">
                <Link to={"mobile-app"} className="hover:underline">
                  Mobile Applications
                </Link>
              </li>
              <li className="mb-2">
                <Link to={"ui-ux"} className="hover:underline">
                  UI/UX Design
                </Link>
              </li>
              <li>
                <Link to={"digital-marketing"} className="hover:underline">
                  Digital Marketing
                </Link>
              </li>
            </ul>
          </div>

          {/* Support */}
          <div>
            <h5 className="text-lg font-bold mb-4">Support</h5>
            <ul>
              <li className="mb-2">
                <Link to={"faq"} className="hover:underline">
                  FAQs
                </Link>
              </li>
              <li className="mb-2">
                <Link to={"help-center"} className="hover:underline">
                  Help Center
                </Link>
              </li>
              <li className="mb-2">
                <Link to={"contact-support"} className="hover:underline">
                  Contact Support
                </Link>
              </li>
              <li>
                <Link to={"privacy-policy"} className="hover:underline">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h5 className="text-lg font-bold mb-4">Contact</h5>
            <p className="mb-2 flex items-center">
              <span className="mr-2">📍</span> Bhubaneswar, Odisha, India
            </p>
            <p className="mb-2 flex items-center">
              <span className="mr-2">📧</span> info@innovativedev.in
            </p>
            <p className="mb-2 flex items-center">
              <span className="mr-2">📞</span> +91 7978763604
            </p>
          </div>
        </div>

        {/* Social Media */}
        <div className="flex justify-between items-center py-6">
          <p className="text-sm">
            © 2024 InnovativeDev. All rights reserved.
          </p>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61570509564772"
              className="text-blue-200 hover:text-white transition-colors duration-200"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>
            <a
              href="#"
              className="text-blue-200 hover:text-white transition-colors duration-200"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/innovativedev12/"
              className="text-blue-200 hover:text-white transition-colors duration-200"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.325c-2.293 0-4.162-1.869-4.162-4.162s1.869-4.162 4.162-4.162 4.162 1.869 4.162 4.162-1.869 4.162-4.162 4.162zm6.406-11.845c-.796 0-1.441-.645-1.441-1.442s.645-1.442 1.441-1.442 1.441.645 1.441 1.442-.645 1.442-1.441 1.442z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/innovative-dev-6765a0341/"
              className="text-blue-200 hover:text-white transition-colors duration-200"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11.75 20h-3.5v-11h3.5v11zm-1.75-12.272c-1.09 0-1.978-.884-1.978-1.978s.885-1.978 1.978-1.978 1.978.884 1.978 1.978c0 1.09-.885 1.978-1.978 1.978zm14.25 12.272h-3.5v-5.606c0-1.338-.027-3.062-1.867-3.062-1.868 0-2.153 1.46-2.153 2.967v5.701h-3.5v-11h3.365v1.506h.048c.467-.879 1.607-1.806 3.31-1.806 3.536 0 4.184 2.327 4.184 5.353v5.947z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
