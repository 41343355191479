import React from 'react';

const ContactUs = () => {
    return (
        <section id="contact" className="bg-gray-50 py-12">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-extrabold text-gray-800 sm:text-4xl">
                        Get in Touch
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Have questions or need help? Feel free to contact us anytime!
                    </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Contact Form */}
                    <form className="bg-white shadow-lg rounded-lg p-8">
                        <div className="mb-6">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Full Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Enter your name"
                                className="mt-1 w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                className="mt-1 w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="message"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Message
                            </label>
                            <textarea
                                id="message"
                                placeholder="Write your message"
                                rows="4"
                                className="mt-1 w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
                        >
                            Send Message
                        </button>
                    </form>

                    {/* Contact Details */}
                    <div className="flex flex-col justify-center">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">
                            Contact Information
                        </h3>
                        <p className="text-lg text-gray-600 mb-6">
                            We’d love to hear from you! Here’s how you can reach us.
                        </p>
                        <div className="space-y-4">
                            <p className="flex items-center text-gray-700">
                                📍 <span className="ml-3">Bhubaneswar, Odisha, India</span>
                            </p>
                            <p className="flex items-center text-gray-700">
                                📧 <span className="ml-3">info@innovativedev.in</span>
                            </p>
                            <p className="flex items-center text-gray-700">
                                📞 <span className="ml-3">+91 7978763604</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
