import React, { useState } from "react";

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "What services do you provide?",
            answer:
                "We offer services in web development, mobile application development, software development, and SEO to elevate your online presence.",
        },
        {
            question: "Which technologies do you specialize in?",
            answer:
                "We specialize in MERN stack, PHP, WordPress, Laravel, Next.js, and other JavaScript frameworks and libraries.",
        },
        {
            question: "How long does it take to complete a project?",
            answer:
                "The timeline depends on the project scope and complexity. On average, small projects take 2-4 weeks, while larger ones may take a few months.",
        },
        {
            question: "Do you offer ongoing support after project completion?",
            answer:
                "Yes, we provide maintenance and support services to ensure your project continues to perform optimally.",
        },
        {
            question: "How do I get started with your services?",
            answer:
                "You can contact us through our website, email, or phone, and we’ll schedule a consultation to discuss your requirements.",
        },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div id="faq" className="bg-gradient-to-r from-indigo-500 to-purple-500 py-16 px-6 sm:px-12 lg:px-24">
            {/* Heading Section */}
            <div className="text-center mb-12">
                <h2 className="text-4xl font-extrabold text-white">
                    Frequently Asked Questions
                </h2>
                <p className="text-indigo-200 mt-4 text-lg">
                    Everything you need to know about our services and expertise.
                </p>
            </div>

            {/* FAQ Items */}
            <div className="space-y-8 max-w-4xl mx-auto">
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className={`rounded-xl overflow-hidden shadow-lg transition-all ${openIndex === index
                                ? "bg-white"
                                : "bg-white/90 hover:bg-white/95"
                            }`}
                    >
                        {/* Question Section */}
                        <div
                            className="flex justify-between items-center p-6 cursor-pointer transition-all"
                            onClick={() => toggleFAQ(index)}
                        >
                            <h3
                                className={`text-lg font-semibold ${openIndex === index
                                        ? "text-indigo-600"
                                        : "text-gray-800 hover:text-indigo-500"
                                    }`}
                            >
                                {faq.question}
                            </h3>
                            <span
                                className={`text-2xl font-bold transition-transform duration-300 ${openIndex === index ? "rotate-180 text-indigo-600" : "text-gray-400"
                                    }`}
                            >
                                ▼
                            </span>
                        </div>

                        {/* Answer Section */}
                        {openIndex === index && (
                            <div className="p-6 bg-indigo-50 text-gray-700">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
