import React from 'react';

const AboutUs = () => {
    return (
        <section id="about-us" className="bg-gradient-to-b from-gray-50 to-white py-16">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    {/* About Us Content */}
                    <div>
                        <h2 className="text-4xl font-extrabold text-gray-800 sm:text-5xl leading-tight">
                            About Us
                        </h2>
                        <p className="mt-6 text-lg text-gray-600 leading-relaxed">
                            At <span className="text-indigo-600 font-semibold">Innovative Dev</span>, we believe every tech journey is unique. Whether you’re a startup preparing for investment, a business seeking efficient technological solutions, or a multinational enterprise in pursuit of fresh answers to persistent challenges, we are here to help.
                        </p>
                        <p className="mt-4 text-lg text-gray-600 leading-relaxed">
                            Our mission is to envision, design, and deliver the perfect solutions to meet your software development needs. From ideation to execution, we combine innovation, expertise, and a deep understanding of technology to create software that aligns with your goals and drives success.
                        </p>

                        {/* Why Choose Us Section */}
                        <div className="mt-10 bg-white p-6 rounded-xl shadow-md border border-gray-200">
                            <h3 className="text-2xl font-bold text-indigo-600 text-center mb-6">
                                Why Choose Us?
                            </h3>
                            <div className="space-y-6">
                                {/* Tailored Solutions */}
                                <div className="flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                                        <div className="w-12 h-12 bg-indigo-600 text-white flex items-center justify-center rounded-full">
                                            <i className="fas fa-cogs text-xl"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-700">Tailored Solutions</h4>
                                        <p className="text-gray-600">
                                            Every project is customized to fit your unique challenges.
                                        </p>
                                    </div>
                                </div>

                                {/* Expertise Across Industries */}
                                <div className="flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                                        <div className="w-12 h-12 bg-indigo-600 text-white flex items-center justify-center rounded-full">
                                            <i className="fas fa-industry text-xl"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-700">Expertise Across Industries</h4>
                                        <p className="text-gray-600">
                                            From startups to global enterprises, we cater to diverse needs.
                                        </p>
                                    </div>
                                </div>

                                {/* Future-Ready Designs */}
                                <div className="flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                                        <div className="w-12 h-12 bg-indigo-600 text-white flex items-center justify-center rounded-full">
                                            <i className="fas fa-rocket text-xl"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-700">Future-Ready Designs</h4>
                                        <p className="text-gray-600">
                                            We build scalable, forward-thinking software that evolves with you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* About Us Image */}
                    <div className="relative">
                        <img
                            src="https://img.freepik.com/free-photo/about-as-service-contact-information-concept_53876-138509.jpg"
                            alt="About Us"
                            className="rounded-xl shadow-lg"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-black/20 rounded-xl"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
