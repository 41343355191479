import React, { useEffect, useState } from "react";

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What is Web Design?",
            answer:
                "Web design is the process of creating the visual layout, structure, and user experience of a website. It includes everything from layout to color scheme, typography, and overall functionality.",
        },
        {
            question: "How long does it take to design a website?",
            answer:
                "The timeline for designing a website depends on its complexity. A simple website might take 2-4 weeks, while more complex sites could take a few months. We’ll work with you to set realistic expectations.",
        },
        {
            question: "Do you offer ongoing website maintenance?",
            answer:
                "Yes! We provide ongoing maintenance to ensure your website runs smoothly, is up to date, and continues to meet your business needs. Contact us for more details.",
        },
        {
            question: "How do I get started with your design services?",
            answer:
                "You can get started by contacting us through our website. We’ll schedule a consultation to understand your needs and goals before beginning the design process.",
        },
        {
            question: "Can you redesign an existing website?",
            answer:
                "Absolutely! We specialize in website redesigns to help businesses improve their online presence. Contact us to discuss how we can revamp your site and make it more effective.",
        },
    ];

    return (
        <div className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
                {/* Hero Section */}
                <section className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-blue-600 mb-4">Frequently Asked Questions</h1>
                    <p className="text-lg text-gray-700">
                        Get answers to the most common questions about our web design services.
                    </p>
                </section>

                {/* FAQ Accordion Section */}
                <section className="space-y-8">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            <div
                                className="flex justify-between items-center cursor-pointer"
                                onClick={() => handleToggle(index)}
                            >
                                <h3 className="text-xl font-semibold text-gray-800">{faq.question}</h3>
                                <span
                                    className={`transform transition duration-200 ${activeIndex === index ? "rotate-180" : ""
                                        }`}
                                >
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                            </div>
                            {activeIndex === index && (
                                <p className="mt-4 text-gray-600">{faq.answer}</p>
                            )}
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default FAQPage;
